import React from 'react';

import Layout from '../components/Layout';
import {Card, MicroCard, ImageCard} from '../components/Card';



import FlexibilityImage from '../assets/images/icon-flexibility.svg';
import ScalabilityImage from '../assets/images/icon-scalability.svg';
import EconomicAdvantageImage from '../assets/images/icon-economicadvantages.svg';
import VariableClientsImage from '../assets/images/icon-clients.svg';
import CloudInfraImage from '../assets/images/img-cloudinfra.png';
import EnableCloudImage from '../assets/images/icon-cloudenable.svg';

import TransformImage from '../assets/images/icon-transform.svg';
import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import D365Logo from '../assets/images/D365-logo-gr.png';
import GraphiteLogo from '../assets/images/graphitegtc-logo-gr.svg';
import IBMLogo from '../assets/images/ibm-cloud-logo-gr.png';
import GCPLogo from '../assets/images/gcp-logo-gr.png';
import AzureLogo from '../assets/images/msazure-logo-gr.png';
import PublicSectorImage from '../assets/images/icon-publicsector.svg';

const herobg = '#1c2841', herofcolor='#efefef';
const CloudPage = () => (
  <Layout>
      <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                        <h3 >Enable the Cloud</h3>
                        <h1 style={{letterSpacing:'.1em'}}>Migrate solutions to the cloud and unleash the business potential.</h1>
                </header>
        </div>
    
        
        <div className="inner row" style={{margin:'.25em auto'}}>
            <div className='col-9 col-12-medium' style={{marginTop:'10px'}}>
                <span className="image left">
                    <img src={EnableCloudImage} alt="Enable the cloud" />
                </span>
            
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Cloud enabled companies are realizing the benefits of mature virtualization 
                    capabilities. For some, moving to the cloud is a difficult and complicated process, 
                    that presents significant business risks.
                    <br /> <br />
                    With our solution deployment for hybrid, public and private cloud, 
                    we can optimize the technology solutions and deliver flexible, responsive services 
                    to your business users.

                </p> 
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    We enable your business seamlessly and migrate solutions to the cloud.
                </p>
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'.5em auto 1em'}}>
                    <Card className='col-6' cardTitle="Scalability" cardImage={ScalabilityImage} >
                        The ability to scale on demand is one of the biggest advantages of cloud computing. 
                    </Card>     
                    <Card className='col-6' cardTitle="Flexibility" cardImage={FlexibilityImage}>
                        Cloud infrastructure provides flexible facilities that can be scaled up, down or switched off.
                    </Card>
                    <Card className='col-6' cardTitle="Economic Advantages" cardImage={EconomicAdvantageImage}>
                        The savings from energy utilization, capital for infrastructure 
                        resources, scaling, infrastructure management are huge.
                    </Card>
                    <Card className='col-6' cardTitle="Variable Clients" cardImage={VariableClientsImage}>
                        Through usage of web services, a robust platform can be created and exposed 
                        to users to allow for more variance to clients. 
                    </Card>
                </div>
                <div className="inner row">    
                    <p style={{fontSize:'110%', fontWeight:400, margin:'1em 0 .5em'}}>
                        Infrastructure Transformation
                    </p>
                    <span className="image fit">
                        <img src={CloudInfraImage} alt="Public Sector" 
                            style={{maxWidth:'600px', margin:'auto'}}/>
                    </span>
                    <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                        Our solution experts will analyze your environment and leverage the latest 
                        cloud technologies to offer you the efficient and cost-effective solution model. 
                        we have the expertise to deliver the best Infrastructure transformation solution.

                    </p>
                </div>
            
            </div>
            <div className='col-3 col-6-medium col-12-small' >
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Explore</p>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Transformation" cardImage={TransformImage} 
                        cardLink='/transform-your-business'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Business Solutions" cardImage={SolutionsImage} 
                        cardLink='/business-solutions-on-the-cloud'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                        cardLink='/public-sector-services'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Talent Services" cardImage={TalentImage} 
                        cardLink='/talent-services'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                    cardLink='/lowcode-nocode'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Modernize" cardImage={ModernizeImage} 
                    cardLink='/modernize-solutions'/>
                    
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Technology Partners</p>
                <ImageCard className='col-12 col-6-medium' cardImage={GraphiteLogo} height='20px'/>
                <ImageCard className='col-12 col-6-medium' cardImage={AzureLogo} height='20px'/>
                <ImageCard className='col-12 col-6-medium' cardImage={GCPLogo}  height='20px'/>
                <ImageCard className='col-12 col-6-medium' cardImage={IBMLogo} height='25px'/>
                
                <ImageCard className='col-12 col-6-medium' cardImage={D365Logo} height='35px'/>
                
            
            </div>
        </div>
       
    </div>
  </Layout>
);

export default CloudPage;
